@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.chart-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%; /* Adjust this value to set the aspect ratio of the charts */
  }
  
  .chart-container canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 640px) and (orientation: portrait) {
    .chart-container {
      height: 400px !important;
    }
  }

  .chart-wrapper {
    height: 300px; /* Default height */
  }
  
  @media screen and (max-width: 640px) {
    .chart-wrapper {
      height: 450px; /* Taller on mobile devices */
    }
  }
  
  @media screen and (max-width: 640px) and (orientation: landscape) {
    .chart-wrapper {
      height: 300px; /* Adjust for landscape orientation on mobile */
    }
  }

  .prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: disc;
    margin-top: 0.25em !important;
    margin-bottom: 1.25em;
    padding-inline-start: 1.625em;
  }  
  .prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0.25em !important;
  }
  .text-error, .text-success  {
    font-weight: bold !important;
  }
.dashboardTotals {background-color: #f2f2f2 !important; padding-top: 0.75em; padding-bottom: 0.01em;}
